@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .breadcrumb-container {
    padding-right: var(--padding-sm);
  }
}
#page-order .main-column .page-content-wrapper .container.my-4 {
  background-color: var(--fg-color);
}
@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .container.my-4 {
    padding: 1.25rem 1.5rem;
    border-radius: var(--border-radius-md);
    box-shadow: var(--card-shadow);
  }
}

@media screen and (max-width: 567px) {
  .indicator-container {
    padding-bottom: 0.8rem;
  }
}

.order-items {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--border-color);
  color: var(--gray-700);
}
@media screen and (max-width: 567px) {
  .order-items {
    align-items: flex-start !important;
  }
}
@media screen and (max-width: 567px) {
  .order-items .col-2 {
    flex: auto;
    max-width: 28%;
  }
}
.order-items .order-item-name {
  font-size: var(--text-base);
  font-weight: 500;
}
.order-items .btn:focus,
.order-items .btn:hover {
  background-color: var(--control-bg);
}
@media screen and (max-width: 567px) {
  .order-items .col-6 {
    max-width: 100%;
  }
}
.order-items .col-6.order-item-name {
  font-size: var(--text-base);
}

.item-grand-total {
  font-size: var(--text-base);
}

.list-item-name,
.item-total,
.order-container,
.order-qty {
  font-size: var(--text-md);
}

@media screen and (max-width: 567px) {
  .d-s-n {
    display: none;
  }
}

@media screen and (min-width: 567px) {
  .d-l-n {
    display: none;
  }
}

.border-btm {
  border-bottom: 1px solid var(--border-color);
}

.order-taxes {
  display: flex;
}
@media screen and (min-width: 567px) {
  .order-taxes {
    justify-content: flex-end;
  }
}
.order-taxes .col-4 {
  padding-left: 0;
}
.order-taxes .col-4 .col-8 {
  padding-right: 0;
  padding-left: 0;
}
@media screen and (max-width: 567px) {
  .order-taxes .col-4 {
    padding-right: 0;
    flex: auto;
    max-width: 100%;
  }
}

.filter-options {
  max-height: 300px;
  overflow: auto;
}

.address-card {
  cursor: pointer;
  position: relative;
}
.address-card .check {
  display: none;
}
.address-card.active {
  border-color: var(--primary);
}
.address-card.active .check {
  display: inline-flex;
}

.check {
  display: inline-flex;
  padding: 0.25rem;
  background: var(--primary);
  color: white;
  border-radius: 50%;
  font-size: 12px;
  width: 24px;
  height: 24px;
}

.website-list {
  background-color: var(--fg-color);
  padding: 0 var(--padding-lg);
  border-radius: var(--border-radius-md);
}
@media screen and (max-width: 567px) {
  .website-list {
    margin-right: -2rem;
  }
}
.website-list.result {
  border-bottom: 1px solid var(--border-color);
}

.transaction-list-item {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
.transaction-list-item:only-child, .transaction-list-item:last-child {
  border: 0;
}
.transaction-list-item a.transaction-item-link {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 0;
}

.place-order-container {
  text-align: left;
}

.kb-card .card-body > .card-title {
  line-height: 1.3;
}

.list-item-name,
.item-total {
  font-size: var(--font-size-sm);
}

@media screen and (max-width: 567px) {
  .items-preview {
    margin-top: 1rem;
  }
}